import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, clearCookies, localStorageEncrypt, localStorageRemove } from '../../utils/helper';

import {
    GET_COMPANY_DETAILS,
    UPDATE_COMPANY_DETAILS,
    GET_USER_INFORMATION,
    UPDATE_USER_INFORMATION,
    GET_INDUSTRY_TYPE,
    GET_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
    GET_SUBSCRIPTION_LIST,
    ADD_PAYMENT_METHOD,
    DELETE_USER,
    GET_WHITE_LABEL_DETAILS,
    GET_DEEP_LINK_DATA,
    SET_BOT_VOICE,
    SET_WHITE_LABEL_LOGOS,
    FETCH_LOGO,
    TWO_FACTOR_AUTH,
    CONNECT_CALENDER,
    CHECK_CALENDAR_CONNECTION,
    REVOKE_CALENDAR_CONNECTION,
} from '../action/types';
import {
    getCompanyDetailsSuccess,
    getCompanyDetailsFailure,
    updateCompanyDetailsSuccess,
    updateCompanyDetailsFailure,
    getUserInformationSuccess,
    getUserInformationFailure,
    updateUserInformationSuccess,
    updateUserInformationFailure,
    getIndustryTypeSuccess,
    getIndustryTypeFailure,
    getPaymentMethodSuccess,
    getPaymentMethodFailure,
    getPaymentMethod,
    deletePaymentMethodSuccess,
    getSubscriptionListSuccess,
    getSubscriptionListFailure,
    addPaymentMethodSuccess,
    addPaymentMethodFailure,
    deletePaymentMethodFailure,
    deleteUserProfileSuccess,
    deleteUserProfileFailure,
    getWhiteLabelDetailsSuccess,
    getWhiteLabelDetailsFailure,
    getDeepLinkDataSuccess,
    getDeepLinkDataFailure,
    setBotVoiceSuccess,
    setBotVoiceFailure,
    setWhiteLabelLogosSuccess,
    setWhiteLabelLogosFailure,
    fetchLogoSuccess,
    fetchLogoFailure,
    twoFactorUpdateSuccess,
    twoFactorUpdateFailure,
    checkCalendarConnectionSuccess,
    checkCandidateExistsFailure,
    connectCalendarSuccess,
    connectCalendarFailure,
    revokeCalendarConnectionSuccess,
    revokeCalendarConnectionFailure,
} from '../action';
import EMPLOYERS from '../../utils/api/employers';
import USERS from '../../utils/api/users';
import ORDERS from '../../utils/api/orders';
import INDUSTRY from '../../utils/api/industry';
import AUTH from '../../utils/api/auth';
import CALENDAR from '../../utils/api/calendar';

function* twoFactorUpdateRequest(action) {
    try {
        const res = yield USERS.get('users/twoFactorUpdate');
        if (res.status === 200 || res.status === 201) {
            yield put(twoFactorUpdateSuccess());
            yield call(action.payload.callback, res.status);
        }
    } catch (e) {
        yield put(twoFactorUpdateFailure());
    }
}
function* fetchLogoRequest() {
    try {
        const res = yield USERS.get('users/getLogo');
        if (res.status === 200 || res.status === 201) {
            yield put(fetchLogoSuccess(res.data.result));
        }
    } catch (e) {
        yield put(fetchLogoFailure());
    }
}

function* getWhiteLabelDetailsRequest(action) {
    try {
        const res = yield USERS.get(`employers/checkWhiteLabelledLogo/${action.payload.productId}`);
        if (res.status === 200) {
            yield put(getWhiteLabelDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getWhiteLabelDetailsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getCompanyDetailsRequest(action) {
    try {
        const res = yield EMPLOYERS.get(`employers/${action.payload}`);
        if (res.status === 200) {
            yield put(getCompanyDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getCompanyDetailsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* updateCompanyDetailsRequest(action) {
    const formData = new FormData();
    formData.append('name', action.payload.data?.name);
    formData.append('email', action.payload.data?.email);
    formData.append('address', JSON.stringify(action?.payload?.data?.address));
    formData.append('companyFoundedIn', action.payload.data?.companyFoundedIn);
    formData.append('industryType', action.payload.data?.industryType);
    formData.append('aboutUs', action.payload.data?.aboutUs);
    if (action.payload.data?.logo) {
        formData.append('logo', action.payload.data?.logo);
    }
    if (action.payload.data?.whiteLogo) {
        formData.append('whiteLogo', action.payload.data?.whiteLogo);
    }
    if (action.payload.data?.linkedInUrl) {
        formData.append('linkedInUrl', action.payload.data?.linkedInUrl);
    }
    if (action.payload.data?.companyWebsiteUrl) {
        formData.append('websiteUrl', action.payload.data?.companyWebsiteUrl);
    }
    if (action.payload.data?.instagramUrl) {
        formData.append('instagramUrl', action.payload.data?.instagramUrl);
    }
    if (action.payload.data?.facebookUrl) {
        formData.append('facebookUrl', action.payload.data?.facebookUrl);
    }
    try {
        const res = yield EMPLOYERS.put(`employers/${action?.payload.id}/company`, formData);
        if (res.status === 200) {
            localStorageEncrypt('companyData', JSON.stringify(res.data.result));
            yield toast.success(<Toast msg="Company details updated successfully." />);
            yield put(updateCompanyDetailsSuccess());
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(updateCompanyDetailsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getUserInformationRequest(action) {
    try {
        const res = yield USERS.get(`users/${action.payload.id}`);
        if (res.status === 200) {
            if (res.data?.result?._id) {
                localStorageRemove('userData');
                localStorageEncrypt('userData', JSON.stringify(res.data.result));
            }
            yield put(getUserInformationSuccess(res.data.result));
            yield call(action.payload.callback, res.data.result);
        }
    } catch (e) {
        yield put(getUserInformationFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* updateUserInformationRequest(action) {
    try {
        const formData = new FormData();
        let contact = action?.payload?.data?.contact;
        let countryCode = action?.payload?.data?.countryCode;
        // let newContact = contact.slice(countryCode.length - 1, contact.length);
        formData.append('name', action.payload.data?.fullName);
        formData.append('phone', action.payload.data?.contact);
        formData.append('countryCode', action?.payload?.data?.countryCode);
        // formData.append("address1", action.payload.data?.addressOne);
        // formData.append("address2", action.payload.data?.addressTwo);
        // formData.append("pincode", action.payload.data?.pincode);
        // formData.append("city", action.payload.data?.city);
        // formData.append("state", action.payload.data?.state);
        if (action.payload.data?.photo) {
            formData.append('photo', action.payload.data?.photo);
        }
        const res = yield USERS.put(`users/${action?.payload?.id}`, formData);
        if (res.status === 200) {
            if (res.data?.result?._id) {
                localStorageRemove('userData');
                localStorageEncrypt('userData', JSON.stringify(res.data.result));
            }
            yield call(action.payload.callback);
            yield put(updateUserInformationSuccess());
            yield toast.success(<Toast msg="User information updated successfully." />);
        }
    } catch (e) {
        yield put(updateUserInformationFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getIndustryTypeRequest() {
    try {
        const res = yield INDUSTRY.get('industries');
        if (res.status === 200) {
            yield put(getIndustryTypeSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getIndustryTypeFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getPaymentMethodRequest() {
    try {
        const res = yield ORDERS.get('payments');
        if (res.status === 200) {
            yield put(getPaymentMethodSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPaymentMethodFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deletePaymentMethodRequest(action) {
    try {
        const res = yield ORDERS.delete(`payments/${action.payload.paymentMethodId}`);
        if (res.status === 204) {
            yield put(getPaymentMethod());
            yield put(deletePaymentMethodSuccess());
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(deletePaymentMethodFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getSubscriptionListRequest(action) {
    try {
        const isActive = action.payload ? `?isActive=${action.payload.isActive}` : '';
        const res = yield ORDERS.get(`subscriptions${isActive}`);
        if (res.status === 200) {
            yield put(getSubscriptionListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSubscriptionListFailure());
    }
}

function* addPaymentMethodRequest(action) {
    try {
        const res = yield ORDERS.post(`payments/request-save-cards`, action.payload.data);
        if (res.status === 201) {
            yield put(addPaymentMethodSuccess());
            const options = {
                ...res.data?.result,
                handler: async (response) => {
                    await ORDERS.put(`payments/success-save-card-request/${response.razorpay_payment_id}`, {
                        paymentMethodId: res.data?.result?.paymentMethodId,
                    });
                    await action.payload.callback();
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
    } catch (e) {
        yield put(addPaymentMethodFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deleteProfileRequest(action) {
    try {
        const res = yield USERS.delete(`/users/${action.payload.id}`);
        if (res.status === 204) {
            yield put(deleteUserProfileSuccess());
            yield toast.success(<Toast msg="Your account has been deleted successfully." />);
            localStorage.clear();
            clearCookies();
            yield call(action.payload.callback());
        }
    } catch (e) {
        yield put(deleteUserProfileFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getDeepLinkDataRequest(action) {
    try {
        const userId = action?.payload?.userId ? action?.payload?.userId : '';
        const deepLinkId = action?.payload?.deepLinkId ? action?.payload?.deepLinkId : '';
        const res = yield USERS.get(`auth/mailDeeplink/${userId}/${deepLinkId}`);
        if (res?.status === 200 || res?.status === 201) {
            yield put(getDeepLinkDataSuccess(res?.data?.result));
            yield call(action?.payload?.callback(res?.data?.result?.data));
        }
    } catch (e) {
        yield put(getDeepLinkDataFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}
function* setBotVoiceRequest(action) {
    try {
        const res = yield AUTH.post('users/incbot/userSettings', { voice: action.payload?.voice?.value });
        if (res?.status === 200 || res?.status === 201) {
            yield put(setBotVoiceSuccess());
            yield toast.success(<Toast msg="Bot Voice Changed." />);
        } else yield put(setBotVoiceFailure());
    } catch (e) {
        yield put(setBotVoiceFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* setWhiteLabelLogosRequest(action) {
    try {
        const res = yield AUTH.post('employers/companyLogo', action.payload.formData);
        if (res?.status === 200 || res?.status === 201) {
            yield put(setWhiteLabelLogosSuccess());
            if (action.payload?.callback) yield call(action.payload?.callback);
            const removeLogo = action.payload?.formData?.get('removeLogo');
            const removeWhiteLogo = action.payload?.formData?.get('removeWhiteLogo');
            const logo = action.payload?.formData?.get('logo');
            const whiteLogo = action.payload?.formData?.get('whiteLogo');
            if (removeLogo === 'false' && removeWhiteLogo === 'false') {
                const msg = logo && whiteLogo ? 'Logos have been Saved.' : 'Logo has been Saved.';
                yield toast.success(<Toast msg={msg} />);
            }
        } else yield put(setWhiteLabelLogosFailure());
    } catch (e) {
        yield put(setWhiteLabelLogosFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* connectCalendarRequest(action) {
    try {
        const res = yield CALENDAR.post('/calendarIntegration/connectCalendar', action.payload.body);
        if (res?.status === 200 || res?.status === 201) {
            yield call(action.payload.callback);
            yield put(connectCalendarSuccess(res?.data?.result));
        } else yield put(connectCalendarFailure());
    } catch (e) {
        yield put(connectCalendarFailure());
    }
}

function* checkCalendarConnectionRequest(action) {
    try {
        const res = yield CALENDAR.post('calendarIntegration/CheckCalendarConnection', action?.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(checkCalendarConnectionSuccess(res?.data?.result));
        }
    } catch (e) {
        yield put(checkCandidateExistsFailure());
    }
}

function* revokeCalendarConnectionRequest(action) {
    try {
        const res = yield CALENDAR.get('/calendarIntegration/revokeCalendarConnection');
        if (res.status === 200 || res.status === 201) {
            yield put(revokeCalendarConnectionSuccess());
            yield call(action?.payload?.callback);
        } else yield put(revokeCalendarConnectionFailure());
    } catch (e) {
        yield put(revokeCalendarConnectionFailure());
    }
}

export function* watchTwoFactorAPI() {
    yield takeEvery(TWO_FACTOR_AUTH, twoFactorUpdateRequest);
}
export function* watchFetchLogoAPI() {
    yield takeEvery(FETCH_LOGO, fetchLogoRequest);
}
export function* watchGetDeepLinkDataAPI() {
    yield takeEvery(GET_DEEP_LINK_DATA, getDeepLinkDataRequest);
}

export function* watchGetWhiteLabelDetailsAPI() {
    yield takeEvery(GET_WHITE_LABEL_DETAILS, getWhiteLabelDetailsRequest);
}
export function* watchGetCompanyDetailsAPI() {
    yield takeEvery(GET_COMPANY_DETAILS, getCompanyDetailsRequest);
}

export function* watchUpdateCompanyDetailsAPI() {
    yield takeEvery(UPDATE_COMPANY_DETAILS, updateCompanyDetailsRequest);
}

export function* watchGetUserInformationAPI() {
    yield takeEvery(GET_USER_INFORMATION, getUserInformationRequest);
}

export function* watchUpdateUserInformationAPI() {
    yield takeEvery(UPDATE_USER_INFORMATION, updateUserInformationRequest);
}

export function* watchGetIndustryTypeAPI() {
    yield takeEvery(GET_INDUSTRY_TYPE, getIndustryTypeRequest);
}

export function* watchGetPaymentMethodAPI() {
    yield takeEvery(GET_PAYMENT_METHOD, getPaymentMethodRequest);
}

export function* watchDeletePaymentMethodAPI() {
    yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethodRequest);
}

export function* watchGetSubscriptionListAPI() {
    yield takeEvery(GET_SUBSCRIPTION_LIST, getSubscriptionListRequest);
}

export function* watchAddPaymentMethodAPI() {
    yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethodRequest);
}

export function* watchDeleteUserProfileAPI() {
    yield takeEvery(DELETE_USER, deleteProfileRequest);
}

export function* watchSetBotVoiceAPI() {
    yield takeEvery(SET_BOT_VOICE, setBotVoiceRequest);
}

export function* watchSetWhiteLabelLogosAPI() {
    yield takeEvery(SET_WHITE_LABEL_LOGOS, setWhiteLabelLogosRequest);
}

export function* watchConnectCalendarAPI() {
    yield takeEvery(CONNECT_CALENDER, connectCalendarRequest);
}

export function* watchCheckCalendarConnectionAPI() {
    yield takeEvery(CHECK_CALENDAR_CONNECTION, checkCalendarConnectionRequest);
}

export function* watchRevokeCalendarConnectionAPI() {
    yield takeEvery(REVOKE_CALENDAR_CONNECTION, revokeCalendarConnectionRequest);
}
export default function* rootSaga() {
    yield all([
        watchTwoFactorAPI(),
        watchFetchLogoAPI(),
        watchGetCompanyDetailsAPI(),
        watchUpdateCompanyDetailsAPI(),
        watchGetUserInformationAPI(),
        watchUpdateUserInformationAPI(),
        watchGetIndustryTypeAPI(),
        watchGetPaymentMethodAPI(),
        watchDeletePaymentMethodAPI(),
        watchGetSubscriptionListAPI(),
        watchAddPaymentMethodAPI(),
        watchDeleteUserProfileAPI(),
        watchGetWhiteLabelDetailsAPI(),
        watchGetDeepLinkDataAPI(),
        watchSetBotVoiceAPI(),
        watchSetWhiteLabelLogosAPI(),
        watchConnectCalendarAPI(),
        watchCheckCalendarConnectionAPI(),
        watchRevokeCalendarConnectionAPI(),
    ]);
}

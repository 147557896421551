import {
    GET_COMPANY_DETAILS,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAILURE,
    UPDATE_COMPANY_DETAILS,
    UPDATE_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY_DETAILS_FAILURE,
    GET_USER_INFORMATION,
    GET_USER_INFORMATION_SUCCESS,
    GET_USER_INFORMATION_FAILURE,
    UPDATE_USER_INFORMATION,
    UPDATE_USER_INFORMATION_SUCCESS,
    UPDATE_USER_INFORMATION_FAILURE,
    GET_INDUSTRY_TYPE,
    GET_INDUSTRY_TYPE_SUCCESS,
    GET_INDUSTRY_TYPE_FAILURE,
    GET_PAYMENT_METHOD,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILURE,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_FAILURE,
    GET_SUBSCRIPTION_LIST,
    GET_SUBSCRIPTION_LIST_SUCCESS,
    GET_SUBSCRIPTION_LIST_FAILURE,
    ADD_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    GET_WHITE_LABEL_DETAILS,
    GET_WHITE_LABEL_DETAILS_FAILURE,
    GET_WHITE_LABEL_DETAILS_SUCCESS,
    GET_DEEP_LINK_DATA,
    GET_DEEP_LINK_DATA_SUCCESS,
    GET_DEEP_LINK_DATA_FAILURE,
    SET_BOT_VOICE,
    SET_BOT_VOICE_SUCCESS,
    SET_BOT_VOICE_FAILURE,
    SET_WHITE_LABEL_LOGOS,
    SET_WHITE_LABEL_LOGOS_SUCCESS,
    SET_WHITE_LABEL_LOGOS_FAILURE,
    FETCH_LOGO,
    FETCH_LOGO_SUCCESS,
    FETCH_LOGO_FAILURE,
    TWO_FACTOR_AUTH,
    TWO_FACTOR_AUTH_SUCCESS,
    TWO_FACTOR_AUTH_FAILURE,
    CONNECT_CALENDER,
    CONNECT_CALENDER_SUCCESS,
    CONNECT_CALENDER_FAILURE,
    CHECK_CALENDAR_CONNECTION,
    CHECK_CALENDAR_CONNECTION_SUCCESS,
    CHECK_CALENDAR_CONNECTION_FAILURE,
    REVOKE_CALENDAR_CONNECTION,
    REVOKE_CALENDAR_CONNECTION_SUCCESS,
    REVOKE_CALENDAR_CONNECTION_FAILURE,
} from './types';
export const twoFactorUpdate = (payload) => ({
    type: TWO_FACTOR_AUTH,
    payload,
});
export const twoFactorUpdateSuccess = () => ({
    type: TWO_FACTOR_AUTH_SUCCESS,
});
export const twoFactorUpdateFailure = () => ({
    type: TWO_FACTOR_AUTH_FAILURE,
});
export const fetchLogo = () => ({
    type: FETCH_LOGO,
});
export const fetchLogoSuccess = (payload) => ({
    type: FETCH_LOGO_SUCCESS,
    payload,
});
export const fetchLogoFailure = () => ({
    type: FETCH_LOGO_FAILURE,
});
export const getWhiteLabelDetails = (payload) => ({
    type: GET_WHITE_LABEL_DETAILS,
    payload,
});
export const getWhiteLabelDetailsSuccess = (payload) => ({
    type: GET_WHITE_LABEL_DETAILS_SUCCESS,
    payload,
});
export const getWhiteLabelDetailsFailure = () => ({
    type: GET_WHITE_LABEL_DETAILS_FAILURE,
});

export const getCompanyDetails = (payload) => ({
    type: GET_COMPANY_DETAILS,
    payload,
});
export const getCompanyDetailsSuccess = (payload) => ({
    type: GET_COMPANY_DETAILS_SUCCESS,
    payload,
});
export const getCompanyDetailsFailure = () => ({
    type: GET_COMPANY_DETAILS_FAILURE,
});

export const updateCompanyDetails = (payload) => ({
    type: UPDATE_COMPANY_DETAILS,
    payload,
});
export const updateCompanyDetailsSuccess = () => ({
    type: UPDATE_COMPANY_DETAILS_SUCCESS,
});
export const updateCompanyDetailsFailure = () => ({
    type: UPDATE_COMPANY_DETAILS_FAILURE,
});

export const getUserInformation = (payload) => ({
    type: GET_USER_INFORMATION,
    payload,
});
export const getUserInformationSuccess = (payload) => ({
    type: GET_USER_INFORMATION_SUCCESS,
    payload,
});
export const getUserInformationFailure = () => ({
    type: GET_USER_INFORMATION_FAILURE,
});

export const updateUserInformation = (payload) => ({
    type: UPDATE_USER_INFORMATION,
    payload,
});
export const updateUserInformationSuccess = () => ({
    type: UPDATE_USER_INFORMATION_SUCCESS,
});
export const updateUserInformationFailure = () => ({
    type: UPDATE_USER_INFORMATION_FAILURE,
});

export const getIndustryType = () => ({
    type: GET_INDUSTRY_TYPE,
});
export const getIndustryTypeSuccess = (payload) => ({
    type: GET_INDUSTRY_TYPE_SUCCESS,
    payload,
});
export const getIndustryTypeFailure = () => ({
    type: GET_INDUSTRY_TYPE_FAILURE,
});

export const getPaymentMethod = (payload) => ({
    type: GET_PAYMENT_METHOD,
    payload,
});
export const getPaymentMethodSuccess = (payload) => ({
    type: GET_PAYMENT_METHOD_SUCCESS,
    payload,
});
export const getPaymentMethodFailure = () => ({
    type: GET_PAYMENT_METHOD_FAILURE,
});

export const deletePaymentMethod = (payload) => ({
    type: DELETE_PAYMENT_METHOD,
    payload,
});
export const deletePaymentMethodSuccess = () => ({
    type: DELETE_PAYMENT_METHOD_SUCCESS,
});
export const deletePaymentMethodFailure = () => ({
    type: DELETE_PAYMENT_METHOD_FAILURE,
});

export const getSubscriptionList = (payload) => ({
    type: GET_SUBSCRIPTION_LIST,
    payload,
});
export const getSubscriptionListSuccess = (payload) => ({
    type: GET_SUBSCRIPTION_LIST_SUCCESS,
    payload,
});
export const getSubscriptionListFailure = () => ({
    type: GET_SUBSCRIPTION_LIST_FAILURE,
});

export const addPaymentMethod = (payload) => ({
    type: ADD_PAYMENT_METHOD,
    payload,
});
export const addPaymentMethodSuccess = () => ({
    type: ADD_PAYMENT_METHOD_SUCCESS,
});
export const addPaymentMethodFailure = () => ({
    type: ADD_PAYMENT_METHOD_FAILURE,
});

export const deleteUserProfile = (payload) => ({
    type: DELETE_USER,
    payload,
});
export const deleteUserProfileSuccess = (payload) => ({
    type: DELETE_USER_SUCCESS,
    payload,
});
export const deleteUserProfileFailure = () => ({
    type: DELETE_USER_FAILURE,
});

export const getDeepLinkData = (payload) => ({
    type: GET_DEEP_LINK_DATA,
    payload,
});

export const getDeepLinkDataSuccess = (payload) => ({
    type: GET_DEEP_LINK_DATA_SUCCESS,
    payload,
});

export const getDeepLinkDataFailure = () => ({
    type: GET_DEEP_LINK_DATA_FAILURE,
});

export const setBotVoice = (payload) => ({
    type: SET_BOT_VOICE,
    payload,
});
export const setBotVoiceSuccess = () => ({
    type: SET_BOT_VOICE_SUCCESS,
});
export const setBotVoiceFailure = () => ({
    type: SET_BOT_VOICE_FAILURE,
});

export const setWhiteLabelLogos = (payload) => ({
    type: SET_WHITE_LABEL_LOGOS,
    payload,
});
export const setWhiteLabelLogosSuccess = () => ({
    type: SET_WHITE_LABEL_LOGOS_SUCCESS,
});
export const setWhiteLabelLogosFailure = () => ({
    type: SET_WHITE_LABEL_LOGOS_FAILURE,
});

export const connectCalendar = (payload) => ({
    type: CONNECT_CALENDER,
    payload,
});
export const connectCalendarSuccess = (payload) => ({
    type: CONNECT_CALENDER_SUCCESS,
    payload,
});
export const connectCalendarFailure = () => ({
    type: CONNECT_CALENDER_FAILURE,
});

export const checkCalendarConnection = (payload) => ({
    type: CHECK_CALENDAR_CONNECTION,
    payload,
});
export const checkCalendarConnectionSuccess = (payload) => ({
    type: CHECK_CALENDAR_CONNECTION_SUCCESS,
    payload,
});
export const checkCalendarConnectionFailure = () => ({
    type: CHECK_CALENDAR_CONNECTION_FAILURE,
});

export const revokeCalendarConnection = (payload) => ({
    type: REVOKE_CALENDAR_CONNECTION,
    payload,
});
export const revokeCalendarConnectionSuccess = () => ({
    type: REVOKE_CALENDAR_CONNECTION_SUCCESS,
});
export const revokeCalendarConnectionFailure = () => ({
    type: REVOKE_CALENDAR_CONNECTION_FAILURE,
});
